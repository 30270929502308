<template>
  <component
    ref="el" v-if="loaded"
    :is="node_map[code]"
    v-bind="node_bind"
  >
    <background v-if="background?.enable"
                :corners="styles?.corners"
                :model-value="background"/>
    <template v-if="dropping.enable">
      <div v-bind="layout_bind" ref="layout_el">
        <template v-if="layout_with_columns">
          <div :class="get_child_col_classes(child)"
               :style="get_child_col_styles(child)"
               :key="child.id"
               v-for="child in visible_children">
            <node v-bind="child"/>
          </div>
        </template>
        <template v-else>
          <node v-bind="child" :key="child.id"
                v-for="child in visible_children"/>
        </template>
      </div>
    </template>
  </component>
</template>
<script setup>
import Background from 'src/components/common/background'
import Node from '../nodes'
import BuiltinHeader from './builtins/headers'
import BuiltinTexts from './builtins/texts'
import BuiltinSection from './builtins/sections'
import BuiltinFooter from './builtins/footers'
import BuiltinTabs from './builtins/tabs'
import BuiltinSlideshows from './builtins/slideshows'
import BuiltinGrids from './builtins/grids'
import PackageForms from './builtins/package-forms'
import BuiltinMedia from './builtins/media'
import FieldInputs from './builtins/field-inputs'
import SubmitButtons from './builtins/submit-buttons'
import ForgetPasswordButtons from './builtins/forget-pw-buttons'
import Forms from './builtins/forms'
import FormErrorMessages from './builtins/form-error-messages'
import BuiltinButtons from './builtins/buttons'
import BuiltinLoginBars from './builtins/login-bars'
import BuiltinContainerBoxes from './builtins/container-boxes'
import BuiltinLanguagePickers from './builtins/language-pickers'
import BuiltinCurrencyPickers from './builtins/currency-pickers'
import BuiltinNavBars from './builtins/nav-bars'
import BuiltinAvatars from './builtins/avatars'
import BuiltinTripForm from './builtins/trip-form'
import BuiltinPackageWidgets from './builtins/package-widgets'

import {defineProps, computed, onMounted, ref, watch} from 'vue'
import {
  NODE_ID_ATTRIBUTE,
  NODE_CLASS,
  NODE_TYPE_ATTRIBUTE,
  NODE_CODE_ATTRIBUTE,
  SECTION_CODE,
  FOOTER_CODE,
  HEADER_CODE,
  DEFAULT_VIEWPORT,
  NODE_ORDER_ATTRIBUTE,
  MEDIA_NODE_CODE,
  FORM_NODE_CODE,
  FORM_SUBMIT_BTN_NODE_CODE,
  FORM_ERROR_MSG_NODE_CODE,
  FIELD_INPUT_NODE_CODE,
  BUTTON_NODE_CODE,
  TEXT_NODE_CODE,
  PACKAGE_FORM_NODE_CODE,
  TAB_NODE_CODE,
  SLIDESHOW_NODE_CODE,
  GRID_NODE_CODE,
  LOGIN_BAR_NODE_CODE,
  CONTAINER_BOX_NODE_CODE,
  LANGUAGE_PICKER_NODE_CODE,
  CURRENCY_PICKER_NODE_CODE,
  GRID_ROW_CLASS,
  GRID_COLUMN_CLASS,
  FORM_FORGET_PW_BTN_CODE,
  LAYOUT_TYPE,
  NAV_BAR_NODE_CODE,
  AVATAR_NODE_CODE,
  TRIP_FORM_NODE_CODE,
  PACKAGE_WIDGET_NODE_CODE, EDIT_MODE
} from 'src/constants'
import {stores} from 'src/stores'
import {generate_common_node_styles} from 'src/composables/canvas'
import cloneDeep from 'lodash.clonedeep'
import {create_event} from 'src/composables/editor'
import {debounce} from 'quasar'

const props = defineProps({
  id: {type: String, required: true},
  parent_id: {type: String},
  order: {type: Number, default: 0},
  type: {type: String, required: true},
  code: {type: String, required: true},
  viewport: {type: String, default: DEFAULT_VIEWPORT},
  dimension: {type: Object, default: () => ({})},
  layout: {type: Object, default: () => ({})},
  dropping: {type: Object, default: () => ({})},
  relation: {type: Object, default: () => ({})},
  background: {type: Object, default: () => ({})},
  styles: {type: Object, default: () => ({})},
  classes: {type: Array, default: () => []},
  children: {type: Array, default: () => []},
  visibility: {type: Object},
  custom_events: {type: Object},
  meta: {type: Object, default: () => ({})}
})

const node_map = {
  [HEADER_CODE]: BuiltinHeader,
  [FOOTER_CODE]: BuiltinFooter,
  [SECTION_CODE]: BuiltinSection,
  [MEDIA_NODE_CODE]: BuiltinMedia,
  [FORM_NODE_CODE]: Forms,
  [FIELD_INPUT_NODE_CODE]: FieldInputs,
  [FORM_SUBMIT_BTN_NODE_CODE]: SubmitButtons,
  [FORM_ERROR_MSG_NODE_CODE]: FormErrorMessages,
  [SLIDESHOW_NODE_CODE]: BuiltinSlideshows,
  [GRID_NODE_CODE]: BuiltinGrids,
  [BUTTON_NODE_CODE]: BuiltinButtons,
  [FORM_FORGET_PW_BTN_CODE]: ForgetPasswordButtons,
  [TEXT_NODE_CODE]: BuiltinTexts,
  [PACKAGE_FORM_NODE_CODE]: PackageForms,
  [TAB_NODE_CODE]: BuiltinTabs,
  [CONTAINER_BOX_NODE_CODE]: BuiltinContainerBoxes,
  [LOGIN_BAR_NODE_CODE]: BuiltinLoginBars,
  [LANGUAGE_PICKER_NODE_CODE]: BuiltinLanguagePickers,
  [NAV_BAR_NODE_CODE]: BuiltinNavBars,
  [CURRENCY_PICKER_NODE_CODE]: BuiltinCurrencyPickers,
  [AVATAR_NODE_CODE]: BuiltinAvatars,
  [TRIP_FORM_NODE_CODE]: BuiltinTripForm,
  [PACKAGE_WIDGET_NODE_CODE]: BuiltinPackageWidgets
}

const el = ref(null)
const layout_el = ref(null)
const loaded = ref(false)

const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const node_classes = computed(() => {
  let result = [
    NODE_CLASS,
    ...props.classes
  ]

  return result
})

const parent = computed(() => {
  if (props.type === LAYOUT_TYPE) return null
  return canvas_store.flattened_nodes[props.parent_id]
})

const node_bind = computed(() => ({
  [NODE_ID_ATTRIBUTE]: props.id,
  [NODE_TYPE_ATTRIBUTE]: props.type,
  [NODE_CODE_ATTRIBUTE]: props.code,
  [NODE_ORDER_ATTRIBUTE]: props.order,
  node: props,
  styles: generate_common_node_styles(canvas_store.flattened_nodes[props.id]),
  parent: parent.value,
  viewport: props.viewport,
  class: node_classes.value
}))

const layout_with_columns = computed(() => {
  const columns = props.layout.columns || {}
  return columns.enable || false
})


const layout_bind = computed(() => {
  let result = {}
  let styles = {}
  let classes = ['q-row', GRID_ROW_CLASS]

  if (props.layout.justify_content) {
    classes.push(props.layout.justify_content)
  }

  if (props.layout.items_align) {
    classes.push(props.layout.items_align)
  }

  if (props.layout.content_align) {
    classes.push(props.layout.content_align)
  }

  if (props.layout.no_wrap) {
    styles['flex-wrap'] = 'nowrap'
  }

  if (layout_with_columns.value && props.layout.columns.gap.enable) {
    classes.push(`q-col-gutter-${props.layout.columns.gap.size}`)
    if (props.type === LAYOUT_TYPE) classes.push('q-mt-none')
  }

  result['class'] = classes
  result['style'] = styles

  return result
})

const visible_children = computed(() => {
  let result = props.children || []

  if (props.code === TAB_NODE_CODE) {
    result = result.filter(child => child.meta?.tab_id === props.meta.tab_id)
  }

  return result
})

const get_child_col_classes = child => {
  const result = [GRID_COLUMN_CLASS]
  const dimension = child.dimension || {}
  const number_of_columns = dimension.columns[context_store.viewport] || 12

  result.push(`q-col-${number_of_columns}`)

  return result
}

const get_child_col_styles = child => {
  let result = {}

  return result
}

let backup_node = null

onMounted(() => {
  loaded.value = true

  if (context_store.mode === EDIT_MODE) {
    backup_node = cloneDeep(canvas_store.nodes[props.id])

    const handle_changes = debounce(function (value, old_value) {
      create_event({
        object_type: 'Node',
        object_id: value.id,
        code: 'Update',
        data: value,
        old_data: old_value
      })
      backup_node = cloneDeep(value)
    }, 500)

    const is_node_changed = (value, old_value) => {
      let cloned_value = cloneDeep(value)
      let cloned_old_value = cloneDeep(old_value)
      const ignore_keys = ['created_at', 'updated_at']

      ignore_keys.forEach(key => {
        delete cloned_value[key]
        delete cloned_old_value[key]
      })

      return JSON.stringify(cloned_value) !== JSON.stringify(cloned_old_value)
    }

    watch(
      () => canvas_store.nodes[props.id],
      (value) => {
        if (!value) {
          // This node is deleted so we ignore
          return
        }
        if (!backup_node) {
          // If no backup node, we ignore also
          return
        }

        if (!is_node_changed(value, backup_node)) {
          // we ignore same data
          return
        }

        handle_changes(value, backup_node)
      },
      {deep: true}
    )
  }

})

</script>
