<template>
  <div
    style="flex-direction: column; flex-wrap: nowrap"
    class="q-row q-col-gutter-sm text-caption text-capitalize"
  >
    <q-item class="q-pr-none" dense v-if="has_type('adult')">
      <q-item-section>
        <q-item-label class="text-capitalize">{{
          $t("common.adults")
        }}</q-item-label>
        <q-item-label caption lines="1">{{
          `${$t("common.ages")} ${adult_min_age} ${$t("common.or")} ${$t(
            "common.above"
          )}`
        }}</q-item-label>
      </q-item-section>
      <q-item-section side>
        <counter
          v-model="travelers_as_object.adult"
          min="1"
          :max="max_available_adults"
        />
      </q-item-section>
    </q-item>
    <q-item class="q-pr-none" dense v-if="has_type('child')">
      <q-item-section>
        <q-item-label class="text-capitalize">{{
          $t("common.children")
        }}</q-item-label>
        <q-item-label caption lines="1">{{
          `${$t("common.ages")} ${child_min_age} - ${child_max_age}`
        }}</q-item-label>
      </q-item-section>
      <q-item-section side>
        <counter
          v-model="travelers_as_object.child"
          min="0"
          :max="max_available_children"
        />
      </q-item-section>
    </q-item>
    <div class="q-col-12 col-sm-6 col-md-4" v-if="children.length">
      <div class="q-row q-col-gutter-sm">
        <div
          :class="get_col_class(children.length)"
          :key="index"
          v-for="(child, index) in children"
        >
          <q-select
            :label="`${$t('common.child')} ${$t('common.age')} #${index + 1}`"
            dense
            outlined
            v-model="child.age"
            color="black"
            :options="child_age_options"
            options-dense
          />
        </div>
      </div>
    </div>
    <q-item class="q-pr-none" dense v-if="has_type('infant')">
      <q-item-section>
        <q-item-label class="text-capitalize">{{
          $t("common.infants")
        }}</q-item-label>
        <q-item-label caption lines="1">{{
          `${$t("common.ages")} ${$t("common.under")} ${infant_max_age}`
        }}</q-item-label>
      </q-item-section>
      <q-item-section side>
        <counter
          v-model="travelers_as_object.infant"
          min="0"
          :max="max_available_infants"
        />
      </q-item-section>
    </q-item>
    <div class="q-col-12 col-sm-6 col-md-4" v-if="infants.length">
      <div class="q-row q-col-gutter-sm">
        <div
          :class="get_col_class(infants.length)"
          :key="index"
          v-for="(infant, index) in infants"
        >
          <q-select
            :label="`${$t('common.infant')} ${$t('common.age')} #${index + 1}`"
            dense
            v-model="infant.age"
            :options="infant_age_options"
            outlined
            options-dense
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import cloneDeep from "lodash.clonedeep";
import { ref, defineProps, computed, watch } from "vue";
import Counter from "src/components/common/counter/index.vue";

const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  max_adults: { type: Number, default: 9 },
  max_children: { type: Number, default: 6 },
  max_infants: { type: Number, default: 3 },
  infant_max_age: { type: Number, default: 2 },
  child_max_age: { type: Number, default: 18 },
  room_number: { type: Number, default: 0 },
  traveler_limit_type: { type: String, default: "per_type" },
  total_traveler_limit: { type: Number, default: 9 },
  traveler_types: { type: Array, default: () => [] },
});
const emit = defineEmits(["update:modelValue"]);

const has_type = (type) =>
  props?.traveler_types?.includes?.(type) ||
  props?.traveler_types?.length === 0;

const total_traveler_limit = props.total_traveler_limit || 9;
const max_adults = props.max_adults || 9;
const max_children = props.max_children || 6;
const max_infants = props.max_infants || 3;
const infant_max_age = props.infant_max_age || 2;
const child_max_age = props.child_max_age || 18;
const room_number = props.room_number || 0;
const array_to_object = (val) => {
  const result = {
    adult: 0,
    child: 0,
    infant: 0,
  };
  Object.keys(result).forEach((traveler_type) => {
    result[traveler_type] = val.filter((t) => t.type === traveler_type).length;
  });
  return result;
};

const travelers = ref([]);
const travelers_as_object = ref({
  adult: 0,
  child: 0,
  infant: 0,
});
if (props.modelValue) {
  travelers.value = cloneDeep(props.modelValue);
  travelers_as_object.value = array_to_object(travelers.value);
}

const child_min_age = computed(() => {
  return infant_max_age;
});

const adult_min_age = computed(() => {
  return child_max_age + 1;
});

const max_available_adults = computed(() => {
  if (props.traveler_limit_type === "by_total") {
    return (
      total_traveler_limit - (children.value.length + infants.value.length)
    );
  }
  return max_adults;
});

const max_available_children = computed(() => {
  if (props.traveler_limit_type === "by_total") {
    return total_traveler_limit - (adults.value.length + infants.value.length);
  }
  return max_children;
});

const max_available_infants = computed(() => {
  if (props.traveler_limit_type === "by_total") {
    return total_traveler_limit - (adults.value.length + children.value.length);
  }
  return max_infants;
});

const children = computed(() => {
  return travelers.value.filter((t) => t.type === "child");
});

const infants = computed(() => {
  return travelers.value.filter((t) => t.type === "infant");
});

const adults = computed(() => {
  return travelers.value.filter((t) => t.type === "adult");
});

const child_age_options = computed(() => {
  let result = Array.from(new Array(child_max_age + 1).keys());
  return result.filter((n) => n >= child_min_age.value);
});

const infant_age_options = computed(() => {
  return Array.from(new Array(infant_max_age + 1).keys());
});

const get_col_class = (data_length) => {
  if (data_length === 1) return "q-col-12";
  return "q-col-6";
};

watch(
  travelers_as_object,
  (val) => {
    Object.keys(val).forEach((traveler_type) => {
      const delta =
        val[traveler_type] -
        travelers.value.filter((t) => t.type === traveler_type).length;
      if (delta > 0) {
        let default_age;
        if (traveler_type === "adult") default_age = adult_min_age.value;
        else if (traveler_type === "child") default_age = child_min_age.value;
        else default_age = 0;
        Array.from(new Array(delta)).forEach(() => {
          travelers.value.push({
            type: traveler_type,
            age: default_age,
            room: room_number,
          });
        });
      } else {
        const abs_delta = Math.abs(delta);
        travelers.value.splice(travelers.value.length - abs_delta, abs_delta);
      }
    });
  },
  { deep: true }
);

watch(
  () => [...travelers.value],
  (val) => {
    emit("update:modelValue", val);
  }
);
</script>
