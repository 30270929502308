import {ref} from 'vue'

export default {
  id: "context",
  setup: {
    state: () => {
      const date_format = ref("YYYY-MM-DD");
      const mode = ref("edit")
      const group = ref(null)
      const app = ref(null)
      const agent = ref(null)
      const template = ref(null)
      const packages = ref([])
      const languages = ref([])
      const products = ref([])
      const language = ref({
        code: "en-US",
        name: "English",
      });
      const currencies = ref([])
      const currency = ref({
        code: "USD",
        name : "US Dollar"
      })
      const viewport = ref("desktop")

      return {
        app,
        agent,
        date_format,
        mode,
        template,
        packages,
        products,
        languages,
        language,
        viewport,
        group,
        currencies,
        currency,
      };
    },
    persist: {
      paths: ['currency','language']
    }
  },
};
