<template>
  <node-canvas :nodes="canvas_store.nested_nodes"/>
</template>
<script setup>

import {stores} from 'src/stores'
import {use_services} from 'src/composables/services'
import {getCurrentInstance, nextTick, onMounted, watch} from 'vue'

import {
  refresh_visible_nodes,
} from 'src/composables/canvas'
import NodeCanvas from 'src/components/node-canvas/index.vue'
import {
  DESKTOP_VIEWPORT,
  MIN_DESKTOP_SCREEN_WIDTH,
  MOBILE_VIEWPORT
} from 'src/constants'


const context_store = stores.use_context()
const canvas_store = stores.use_canvas()
const auth_store = stores.use_auth()

const services = use_services()

const update_viewport = () => {
  let viewport = DESKTOP_VIEWPORT
  if (window.innerWidth < MIN_DESKTOP_SCREEN_WIDTH) {
    viewport = MOBILE_VIEWPORT
  }
  context_store.$patch(state => {
    state.viewport = viewport
  })
}

onMounted(async () => {
  update_viewport()
  await nextTick()

  window.addEventListener('resize', async function () {
    await nextTick()
    update_viewport()
  })
})


watch(
    [
      () => context_store.viewport,
      () => auth_store.user.access_token,
    ],
    () => {
      refresh_visible_nodes()
    },
    {deep: true}
)

</script>
