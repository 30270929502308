<template>
  <q-responsive v-bind="bind">
    <swiper-container ref="slider" init="false">
      <swiper-slide
        v-for="(slide, index) in slides"
        :virtual-index="index"
        :style="slide.slide_style"
        :key="index"
      >
        <media :model-value="slide" :node="props.node" />
      </swiper-slide>
    </swiper-container>
  </q-responsive>
</template>
<script setup>
import { computed, defineProps, onMounted, ref, watch } from 'vue'
import Media from './media'

import { EDIT_MODE, NODE_BASE_PROPS } from 'src/constants'

import {
  generate_common_node_styles
} from 'src/composables/canvas'
import { stores } from 'src/stores'

const props = defineProps(NODE_BASE_PROPS)
const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const slider = ref(null)

const meta = computed(() => {
  return props.node.meta || {}
})

const corners = computed(() => {
  return props.node.styles?.corners
})

const init_slider = () => {
  Object.assign(slider.value, swiper_bind.value)
  slider.value.initialize()

  const instance = slider.value.swiper

  if (
    meta.value.slides &&
    meta.value.slides.length &&
    meta.value.start_from_middle
  ) {
    const slide_count = meta.value.slides.length
    const start_slide_index = Math.round(slide_count / 2) - 1
    instance.slideTo(start_slide_index)
  }
}

const slides = computed(() =>
  (meta.value.slides || []).map((s, index) => {
    let slide_styles = {}

    let media_styles = {}
    if (corners.value.values && corners.value.values.length) {
      media_styles['border-radius'] = corners.value.values
        .map((v) => `${v}px`)
        .join(' ')
    }

    return {
      ...s,
      slide_style: slide_styles
    }
  })
)

const styles = computed(() => generate_common_node_styles(props.node))

const viewport = computed(() => context_store.viewport)
const parent = computed(
  () => canvas_store.flattened_nodes[props.node.parent_id]
)

const bind = computed(() => {
  const ratio = props.node.meta.ratios[context_store.viewport] ?? 2
  let result = {
    style: styles.value,
    ratio: ratio || 2
  }
  if (parent.value.layout.columns.enable) {
    result.style['width'] = '100%'
    result['ratio'] = ratio
  } else {
    result['ratio'] = ratio
    result.style.width = `${props.node.dimension.width || 1000}px`
    result.style.height = `${props.node.dimension.height || 1000}px`
  }
  return result
})

const swiper_bind = computed(() => {
  const infinite = meta.value?.infinite
  const items_to_show = meta.value?.items_to_show
  const space_between = meta.value?.space_between

  let result = {
    centeredSlides: true
  }

  if (items_to_show && items_to_show[viewport.value]) {
    result['slidesPerView'] = parseInt(items_to_show[viewport.value])
  }

  if (space_between && space_between[viewport.value]) {
    result['spaceBetween'] = parseInt(space_between[viewport.value])
  }
  if (infinite) result['loop'] = true

  if (context_store.mode !== EDIT_MODE) {
    if (meta.value.autoplay)
      result['autoplay'] = { disableOnInteraction: false }
  }

  result['style'] = {
    'background-color': 'transparent'
  }

  return result
})

onMounted(() => {
  init_slider()
})

watch(
  () => meta.value,
  () => {
    if (slider.value) {
      Object.assign(slider.value, swiper_bind.value)
    }
  },
  { deep: true }
)
</script>
