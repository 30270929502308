})
<template>
  <div class="q-row q-col-gutter-sm" style="position: relative">
    <div :class="class_name">
      <place-select
        :key="modelValue.dp_airport?.id"
        dense
        v-model="modelValue.dp_airport"
        :types="place_types"
        :rules="rules.dp_airport"
        cities_with_airports
        outlined
        hide-bottom-space
        fill-input
        hide-selected
        :static_route_enabled="product.static_route_enabled"
        :destination_type="product.static_route_enabled ? 'departure' : ''"
        :arrival_code="product.static_route_enabled && modelValue.ar_airport ? modelValue.ar_airport.code : ''"
        prepend_icon="place"
        :has_code="has_code"
        :label="$t('common.origin')"
      />
    </div>
    <div :class="class_name">
      <place-select
        dense
        :key="modelValue.ar_airport?.id"
        v-model="modelValue.ar_airport"
        :types="place_types"
        :rules="rules.ar_airport"
        cities_with_airports
        outlined
        hide-bottom-space
        fill-input
        hide-selected
        :static_route_enabled="product.static_route_enabled"
        :destination_type="product.static_route_enabled ? 'arrival' : ''"
        :departure_code="product.static_route_enabled && modelValue.dp_airport ? modelValue.dp_airport.code : ''"
        prepend_icon="place"
        :has_code="has_code"
        :label="$t('common.destination')"
      />
    </div>
    <q-btn
      round
      :style="`color: ${color}`"
      class="bg-white swap-flight-route-btn"
      :icon="icon"
      outline
      @click="$emit('handle_swap_airport')"
      dense
    />
  </div>
</template>

<script setup>

import { defineProps, computed, watch } from 'vue'
import PlaceSelect from 'src/components/common/place-select/index.vue'
import { stores } from "src/stores"
import { DESKTOP_VIEWPORT } from "src/constants"

const props = defineProps({
  product: { type: Object, required: true },
  color: { type: String },
  modelValue: { type: Object, required: true },
  has_code: {type: Boolean, default: false},
})

const context_store = stores.use_context()

const place_types = computed(() => {
  return [
    'airport',
  ]
})

const class_name = computed(() => {
  if (context_store.viewport === DESKTOP_VIEWPORT) {
    return 'q-col-6'
  } else {
    return 'q-col-12'
  }
})

const icon = computed(() => {
  return context_store.viewport === DESKTOP_VIEWPORT ? 'swap_horiz' : 'swap_vert'
})

const rules = {
  dp_airport: [
    v => !!v || ''
  ],
  ar_airport: [
    v => !!v || ''
  ],
}

</script>
