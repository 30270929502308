<template>
  <q-field :model-value="modelValue" color="black">
    <template v-slot:control>
      <q-item-label class="text-capitalize" lines="1">
        {{ display_value }}
      </q-item-label>
    </template>
    <template v-slot:prepend>
      <q-icon name="group" />
    </template>
    <q-menu
      fit
      max-height="max-content"
      max-width="400px"
      class="eg-traveler-select"
      :style="{ width: '100%' }"
    >
      <q-card :style="{ '--q-primary': color }">
        <template v-if="with_rooms">
          <template v-for="(room, index) in rooms" :key="room.number">
            <q-item>
              <q-item-section>
                <q-item-label class="text-weight-medium text-capitalize">
                  <span>Room</span>
                  {{ ' ' }}
                  <span>{{ room.number }}</span>
                </q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-btn
                  round
                  dense
                  size="sm"
                  v-if="room.number > 1"
                  unelevated
                  @click="remove_room(room.number)"
                  icon="close"
                  color="primary"
                />
              </q-item-section>
            </q-item>
            <q-card-section class="q-pt-none">
              <inputs
                :total_traveler_limit="total_traveler_limit"
                :traveler_limit_type="traveler_limit_type"
                :room_number="Number(rooms[index].number)"
                :max_children="+max_children"
                :max_infants="+max_infants"
                :max_adults="+max_adults"
                :child_max_age="age_policy.child_age"
                :infant_max_age="age_policy.infant_age"
                :traveler_types="traveler_types"
                :color="color"
                v-model="room.travelers"
              />
            </q-card-section>
            <q-separator />
          </template>
          <q-card-section>
            <div class="q-row q-col-gutter-sm">
              <div class="q-col-12">
                <q-item-label
                  @click="add_room"
                  v-if="rooms.length < max_rooms"
                  class="text-weight-medium text-primary cursor-pointer"
                >
                  + Add Room
                </q-item-label>
              </div>
              <div class="q-col-12">
                <q-item-label caption>
                  Maximum room is 3 per booking.
                </q-item-label>
              </div>
              <div class="q-col-12">
                <q-item-label caption>
                  Age of children / infants should be considered from the date
                  of departure.
                </q-item-label>
              </div>
            </div>
          </q-card-section>
        </template>
        <template v-else>
          <q-card-section>
            <inputs
              :total_traveler_limit="total_traveler_limit"
              :traveler_limit_type="traveler_limit_type"
              :room_number="Number(rooms[0].number)"
              :max_children="+max_children"
              :max_infants="+max_infants"
              :max_adults="+max_adults"
              :child_max_age="age_policy.child_age"
              :infant_max_age="age_policy.infant_age"
              :traveler_types="traveler_types"
              :color="color"
              v-model="rooms[0].travelers"
            />
          </q-card-section>
        </template>
      </q-card>
    </q-menu>
  </q-field>
</template>
<script setup>
import cloneDeep from 'lodash.clonedeep'
import { ref, defineProps, defineEmits, computed, watch } from 'vue'
import keyby from 'lodash.keyby'
import keys from 'lodash.keys'
import { stores } from 'src/stores'

import Inputs from './inputs.vue'

const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  with_rooms: { type: Boolean, default: false },
  max_rooms: { type: Number, default: 3 },
  infant_max_age: { type: Number, default: 2 },
  child_max_age: { type: Number, default: 18 },
  max_adults: { type: Number, default: 9 },
  max_children: { type: Number, default: 6 },
  max_infants: { type: Number, default: 3 },
  traveler_limit_type: { type: String, default: 'per_type' },
  total_traveler_limit: { type: Number, default: 9 },
  default_traveler_count: { type: Number, default: 1 },
  traveler_types: { type: Array, default: () => [] },
  color: { type: String, default:'#1976d2' }
})

const context_store = stores.use_context()
const age_policy = computed(() => context_store?.app?.age_policy || {})

const emit = defineEmits(['update:modelValue'])

const init_default_travelers = () => {
  return [...Array(props.default_traveler_count).keys()].map((x) => ({
    type: 'adult',
    age: props.child_max_age,
    room: 1
  }))
}

const should_reset_traveler = () => {
  let should_reset = false
  switch (props.traveler_limit_type) {
    case 'by_total': {
      if (props.modelValue?.length > props.total_traveler_limit) {
        should_reset = true
      }
      break
    }
    default: {
      const adult_count = travelers.value?.filter?.(
        (t) => t.type === 'adult'
      )?.length
      const child_count = travelers.value?.filter?.(
        (t) => t.type === 'child'
      )?.length
      const infant_count = travelers.value?.filter?.(
        (t) => t.type === 'infant'
      )?.length
      if (
        adult_count > props.max_adults ||
        child_count > props.max_children ||
        infant_count > props.max_infants
      ) {
        should_reset = true
      }
    }
  }
  return should_reset
}

const travelers = ref([])

if (props.modelValue && props.modelValue.length && !should_reset_traveler()) {
  props.modelValue.forEach((t) => {
    let traveler = cloneDeep(t)
    if (traveler.room === null || traveler.room === undefined) {
      traveler.room = 1
    }
    travelers.value.push(traveler)
  })
} else {
  travelers.value = init_default_travelers()
  emit('update:modelValue', travelers.value)
}

const rooms = ref(
  keys(keyby(travelers.value, 'room')).map((room_number) => {
    return {
      number: Number(room_number),
      travelers: travelers.value.filter(
        (t) => Number(t.room) === Number(room_number)
      )
    }
  })
)

const display_value = computed(() => {
  const adult_count = travelers.value.filter((t) => t.type === 'adult').length
  const child_count = travelers.value.filter((t) => t.type === 'child').length
  const infant_count = travelers.value.filter((t) => t.type === 'infant').length
  let result = ''
  if (props.with_rooms) {
    result += `${rooms.value.length} rooms, ${adult_count} adults`
  } else {
    result += `${adult_count} adults`
  }
  if (child_count) result += `, ${child_count} children`
  if (infant_count) result += `, ${infant_count} infants`
  return result
})

const remove_room = (number) => {
  const index = rooms.value.findIndex((r) => r.number === number)
  rooms.value.splice(index, 1)
  setTimeout(() => {
    rooms.value
      .filter((r) => r.number > number)
      .forEach((r) => {
        r.number--
        r.travelers.forEach((t) => {
          t.room = r.number
        })
      })
  }, 1)
}

const add_room = () => {
  const new_number = rooms.value.length + 1
  const new_traveler = {
    type: 'adult',
    age: props.child_max_age,
    room: new_number
  }
  const new_room = {
    number: new_number,
    travelers: [new_traveler]
  }
  travelers.value.push(new_traveler)
  rooms.value.push(new_room)
}

watch(
  rooms,
  (val) => {
    let all_travelers = []
    val.forEach((room) => {
      all_travelers = all_travelers.concat(room.travelers)
    })
    travelers.value = all_travelers
    emit('update:modelValue', all_travelers)
  },
  { deep: true }
)
</script>
<style>
.eg-traveler-select {
  min-width: auto !important;
}
</style>
