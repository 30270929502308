import { createI18n } from 'vue-i18n'
import { DEFAULT_LANGUAGE } from 'src/constants'

export const i18n = createI18n({
  locale: DEFAULT_LANGUAGE.code,
  globalInjection: true,
  messages: {
    'en-US': {
      'common.registration-successful':
        'Thank you for registering, We will get in touch shortly to complete the activation process. Thank You! Team {app_name}.',
      'common.registration-successful-title': 'Register Successfully'
    }
  },
  legacy: false
})
